import React from "react";

const Person = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Person">
      <path
        id="Vector"
        d="M49.9609 28.125C56.0016 28.125 60.8984 23.2281 60.8984 17.1875C60.8984 11.1469 56.0016 6.25 49.9609 6.25C43.9203 6.25 39.0234 11.1469 39.0234 17.1875C39.0234 23.2281 43.9203 28.125 49.9609 28.125Z"
        fill="#4D4D4D"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M60.1172 52.6172L73.7109 63.1641C74.6096 63.9121 75.7555 64.2973 76.9235 64.244C78.0916 64.1906 79.1975 63.7027 80.0243 62.8759C80.8512 62.0491 81.3391 60.9431 81.3924 59.7751C81.4457 58.607 81.0606 57.4612 80.3125 56.5625L64.8438 36.6797C64.1229 35.9515 63.2652 35.373 62.3199 34.9775C61.3747 34.5819 60.3606 34.3772 59.3359 34.375H40.586C38.5063 34.3861 36.5143 35.2138 35.0391 36.6797L19.5703 56.5625C18.7649 57.4565 18.3341 58.6261 18.3673 59.829C18.4004 61.0318 18.8948 62.1759 19.7482 63.0243C20.6016 63.8727 21.7486 64.3604 22.9516 64.3864C24.1547 64.4124 25.3217 63.9747 26.2109 63.1641L39.8047 52.6172L28.9453 86.8359C28.6837 87.3951 28.5348 88.0003 28.5071 88.617C28.4793 89.2337 28.5733 89.8498 28.7837 90.4302C28.9941 91.0105 29.3167 91.5438 29.7332 91.9995C30.1497 92.4551 30.6518 92.8243 31.2109 93.0859C31.7701 93.3476 32.3753 93.4965 32.992 93.5242C33.6087 93.5519 34.2248 93.4579 34.8052 93.2475C35.3855 93.0372 35.9188 92.7145 36.3745 92.2981C36.8301 91.8816 37.1993 91.3795 37.461 90.8203L49.961 71.1328L62.4609 90.8203C62.7051 91.4039 63.0649 91.932 63.5186 92.3728C63.9722 92.8136 64.5105 93.1581 65.1008 93.3854C65.6911 93.6127 66.3213 93.7182 66.9535 93.6954C67.5857 93.6727 68.2067 93.5223 68.7792 93.2532C69.3517 92.9841 69.8638 92.6019 70.2847 92.1296C70.7055 91.6574 71.0265 91.1048 71.2281 90.5052C71.4298 89.9057 71.508 89.2715 71.458 88.6409C71.4081 88.0103 71.231 87.3963 70.9375 86.8359L60.1172 52.6172Z"
        fill="#4D4D4D"
        stroke="#4D4D4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default Person;
