import { SVGProps } from "react";

const InsertLogo = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="120"
    height="30"
    viewBox="0 0 120 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9_802)">
      <path
        d="M30.3846 1.34619L9.61539 7.21158L9.71154 29.8077L30.3846 24.0385V1.34619Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M41.4917 5.88856V22.8845H37.9704V5.88856H41.4917ZM58.5346 5.88856V22.8845H55.4829L48.0177 12.086H47.8769V22.8845H44.3556V5.88856H47.4544L54.8725 16.6871H55.0367V5.88856H58.5346ZM70.6242 10.7479C70.4835 9.40981 69.4035 8.61165 67.6898 8.61165C65.9058 8.61165 64.9198 9.43329 64.8964 10.5601C64.8729 11.7808 66.1641 12.3442 67.6194 12.6728L69.1454 13.0484C72.0562 13.7058 74.2394 15.1847 74.2394 18.0252C74.2394 21.1474 71.7981 23.1193 67.6664 23.1193C63.5583 23.1193 60.9291 21.2178 60.8352 17.5791H64.2625C64.3798 19.2928 65.7414 20.1614 67.6194 20.1614C69.4741 20.1614 70.6712 19.2928 70.6712 18.0252C70.6712 16.8749 69.6148 16.335 67.7604 15.8655L65.9058 15.4194C63.0417 14.7152 61.2812 13.2832 61.2812 10.7948C61.2812 7.69614 64.0042 5.65381 67.7133 5.65381C71.4694 5.65381 73.9577 7.74308 74.0046 10.7479H70.6242ZM76.54 22.8845V5.88856H87.9489V8.79946H80.0612V12.9311H87.3619V15.842H80.0612V19.9735H87.9723V22.8845H76.54ZM90.6954 22.8845V5.88856H97.3623C101.236 5.88856 103.442 8.07173 103.442 11.4287C103.442 13.7527 102.374 15.4429 100.438 16.2645L104.053 22.8845H100.156L96.9164 16.8514H94.2167V22.8845H90.6954ZM94.2167 13.9875H96.705C98.7942 13.9875 99.8037 13.1189 99.8037 11.4287C99.8037 9.715 98.7942 8.77598 96.705 8.77598H94.2167V13.9875ZM105.227 8.79946V5.88856H119.147V8.79946H113.936V22.8845H110.438V8.79946H105.227Z"
        fill="white"
      />
      <path
        d="M20.9616 1.15381L0.192322 7.01919L0.288476 29.6153L20.9616 23.8461V1.15381Z"
        fill="white"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_802">
        <rect width="120" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InsertLogo;
